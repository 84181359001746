html {
  overflow-x: hidden;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

body {
  margin: 0;
}

html,
body,
#root {
  height: 100%;
}

.MuiInputLabel-root {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 40px);
  height: 100%;
}
.MuiInputLabel-shrink {
  max-width: 109%;
}

.input-field {
  background: none;
  /* border: $silver 1px solid; */
  /* color: $grey; */
  font-size: inherit;
  font-weight: 100;
  padding: 10px;
  width: calc(100% - 20px);
  /* @extend %-reset-appearance; */
}


.input-field:focus {
  outline: none;
}
